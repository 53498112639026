import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import { PvEDoudizhuDemoView } from './view/PvEView';

const navbarSubtitleMap = {
    '/': '',
};

function App() {
    // todo: add 404 page
    return (
        <Router>
            <Navbar subtitleMap={navbarSubtitleMap} />
            <div style={{ marginTop: '75px' }}>
                <Route path="/" component={PvEDoudizhuDemoView} />
            </div>
        </Router>
    );
}

export default App;
